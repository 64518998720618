.student-view {
    .modal-body{
            height: 99%
    }
    .pg-viewer-wrapper{
        max-height: 400px;
    }
    .student-button-row{
        .student-back {
            font-size: 12px;
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                    padding-left: .75rem;
                    padding-right: .75rem;
                    border-radius: .375rem;
                    font-weight: 500;
                    cursor: pointer;
                    background-color: #1c3faa;
                    border: none;
                    // margin-left: 10px;
                    margin-top: 2px;
        }
        .external{
            float: right;
            .external-icon{
                margin-right: 5px;
            }
        }
    }
   
    .header {
        background-color: #fff;
        margin: 20px 0;
        padding: 10px;
        border-radius: 5px;
        h4 {
            margin: 0;
        }
        .student-back {
            font-size: 12px;
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                    padding-left: .75rem;
                    padding-right: .75rem;
                    border-radius: .375rem;
                    font-weight: 500;
                    cursor: pointer;
                    background-color: #1c3faa;
                    border: none;
                    // margin-left: 10px;
        }
        .external{
            float: right;
            .external-icon{
                margin-right: 5px;
            }
        }
    }
    .task-section{
        background-color: #fff;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 5px;
        ul{
            padding: 0;
            list-style: none;
            li{
            font-size: 15px;
            line-height: 2;
            .iconImage{
            background-color: #fff;
            border: 1px solid silver;
            border-radius: 14px;
            margin-right: 5px;
            padding: 1px 2px;
            }
            .iconImageCheck{
            background-color: green;
            border: none;
            border-radius: 14px;
            margin-right: 5px;
            padding: 1px 2px;
            }
            .iconImageRefresh{
            background-color: blue;
            border: none;
            padding: 1px 2px;
            border-radius: 14px;
            margin-right: 5px;
            }
            }
            }
        .apply-button{
            margin-top: 1rem;
            padding: 5px 26px;
            border: none;
            background-color: #02028e;
            font-weight: 500;
            &:hover{
                transform: scale(1.1);
                background-color: #46468e;
            }
        }
    }
    .course-section{
        background-color: #fff;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 5px;
    }
    .tab-section {
        background-color: #fff;
        padding: 10px;
        border-radius: 5px;
        margin-left: 15px;
        .bp3-tab-list {
            border-bottom: 2px solid #f1f5f8;
        }
    }
    .modal{
        z-index: 20;
    }
    .modal-body {
        max-height: 500px;
        overflow-y: auto;
    }
}