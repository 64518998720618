.open-ai-container{
    .openai-chat{
        height: 60px;
        width: 60px;
        background-color: #204ecf;
        position: fixed;
        right: 20px;
        bottom:20px;
        border-radius: 100%;
        -webkit-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
        box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
        cursor: pointer;
        .external-icon {
            font-size: 38px !important;
            font-weight: normal;
            vertical-align: text-top;
            margin: 12px;
            padding: 0;
            color: #ffffff;
            display: inline-flex;
            align-content: center;
            align-items: flex-end;
            justify-content: center;
          }
       
    }
    }