
.chatBot {
    min-height: 400px;
    // width: 350px;
    background-color: #ffffff;
    position: fixed;
    right: 2%;
    bottom: 15%;
    border-radius: 5%;
    padding: 15px;
    -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    align-content: flex-end;
    align-items: flex-end;

    .topContainer {
        height: 60px;
        width: 100px;
        background-color: #204ecf;
        top: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 15px 15px 0 0;
        z-index: 2;

        .icon-icons-01-indicator-chat:before {
            content: "\e946";
            font-size: 24px;
            font-weight: normal;
            margin: 0 4px 0 0px;
            vertical-align: middle;
        }
    }

    .chatContainer {
        width: 100%;
        ::-webkit-scrollbar {
            width: 0px;
            background: transparent; /* make scrollbar transparent */
        }
        .chats {
            overflow-y: scroll;
            height: 260px;
            padding: 10px;
            margin-top: 40px;
        
            .resultArea {
                white-space: pre-wrap;
                padding: 20px;
                // margin-right: 10%;
                font-size: 15px;
                background-color: #ebeffb;
                box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%), inset 0 -1px 0 rgb(16 22 26 / 10%);
                // max-height: 200px;
                overflow: hidden;
                border-radius: 10px;
            }
        }

        .buttonContainer {
            padding: 15px 10px 10px;

        }

        .chatInput {
            width: 90%;
            display: flex;
            align-items: center;
            // justify-content: space-around;
            // .button{

            // }
            .input{
                height: 48px;
                margin-top: 8px;
                margin-bottom: 8px;
                    // width: 528px;
                    // height: 48px;
                    padding: 12px 12px 12px 16px;
                    border-radius: 4px;
                    border: solid 1px #b7b3b3;
                    background-color:#ffffff;
                    color: #655c5c;
                    &:focus {
                        // width: 528px;
                        height: 48px;
                        padding: 12px 12px 12px 16px;
                        border-radius: 4px;
                        border: 1px solid rgb(10, 10, 250);
                        background-color:  #ffffff;
                      box-shadow: none;
                    }
            }
        }
    }
}